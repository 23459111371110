@import "../../css-config/mixins.scss";
.product-container{
    margin: 50px 0;
    padding: 20px;
    border-radius: 10px;
    @include md{
        margin: 75px 0;
        padding: 20px;
    }
    .sec-heading{
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        @include md{
            margin-bottom: 35px;
            font-size: 24px;

        }
        &:after{
            content: "";
            display: block;
            margin-top: 5px;
            width: 50px;
            height: 3px;
            background-color: #760bd3;
            @include md{
                margin-top: 10px;
            }
        }
    }
    .products{
        display: flex;
        flex-flow: wrap;
        gap: 10px;
        @include md{
            gap: 20px;
        }
    }
}

@import "../../../css-config/mixins.scss";
.newsletter-section{
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    background: #f9f9f9 url("../../../assets/newsletter-bg.jpeg");
    background-position:top center;
    background-repeat: no-repeat;
    background-size: cover;
    
    @include md{
        background-position:bottom center;
        background-repeat: no-repeat;
        width: 100%;
        height: 300px;
        background-size:cover;
    }
    .newsletter-content{
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        text-align: center;

    }
    .small-text{
        margin-bottom: 15px;
        text-transform: uppercase;
        color: rgba($color: #000000, $alpha: 0.5);
    }
    .big-text{
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 0 30px;
        @include md{
            font-size: 28px;
        }
    }
    .form{
        display: flex;
        gap: 5px;
        margin-bottom: 10px;
        input{
            width: 200px;
            height: 40px;
            border-radius: 0;
            border: 1px solid rgba($color: #000000, $alpha: 0.2);
            padding: 0 12px;
            font-size: 16px;
            outline: none;
            @include md{
                width: 300px;
            }
        }
        button{
            outline: 0;
            border: 0;
            height: 40px;
            width: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 16px;
            color: white;
            background-color: #8e2de2;
            border-bottom: 3px solid #8e2de2;
            @include md{
                width: 120px;
            }

        }
    }
    .text{
        margin-bottom: 20px;
        font-size: 14px;
        color: rgba($color: #000000, $alpha: .5);
        @include md{
            font-size: 18px;
        }
    }

    .social-icons{
        display: flex;
        gap: 10px;
        .icon{
            width: 30px;
            height: 30px;
            background-color: rgba(0,0,0,0.8);
            border-radius: 50%;
            color:white;
            justify-content: center;
            display: flex;
            align-items: center;
            @include md{
            width: 30px;
            height: 30px;
            }
        }
    }
}